
@font-face {
  font-family: myFirstFont;
  src: url("./adobe.ttf");
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: myFirstFont !important;
}

